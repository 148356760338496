$skeleton-loading-animation-time: 1.3s !default;
@keyframes ssc-loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
%ssc-animation {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    animation: ssc-loading $skeleton-loading-animation-time infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.3),
      transparent
    );
  }
}
.skeleton {
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
  &.button {
    width: 10%;
    height: 30px;
    margin-top: 15px;
    margin-right: 15px;
    @extend %ssc-animation;
  }
  &.title {
    width: 70%;
    height: 15px;
    margin-top: 15px;
    @extend %ssc-animation;
  }
  &.header {
    width: 70%;
    height: 30px;
    margin-top: 15px;

    @extend %ssc-animation;
  }
}
.skeleton.text {
  width: 100%;
  height: 6px;
}

.skeleton.wraper {
  margin: 20px auto;
  padding: 10px;
  background: #ececec;
  position: relative;
}
.skeleton-wraper {
  // margin: 20px auto;
  padding: 10px;
  background: #ffffff;
  position: relative;
  border-radius: 3px;
}
.skeleton.wraper.light {
  background: #f2f2f2;
}
.skeleton.wraper.dark {
  background: #444;
}
.skeleton.wraper.dark.skeleton {
  background: #777;
}
.skeleton-table {
  table {
    width: 100%;
    td,
    th {
      border-top: none !important;
      padding: 10px 0;
      border-bottom: 1px solid #ebedf3;
    }
  }
}
