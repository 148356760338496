.button {
  background-color: var(--color-primary-2);
  color: #fff;
  border: 0;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: var(--border-radius-4);
  cursor: pointer;
  font-weight: 500;
  transition: box-shadow 100ms ease-out;
  box-shadow: "none";
  opacity: 1;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.75;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}
