.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.title {
  margin-bottom: 2.75rem;
  font-weight: 600;
  font-size: 1.75rem;
}

.subTitle {
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.form {
  max-width: 48rem;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  text-transform: none;
  padding: 3.5rem 3rem;
  padding-top: 1.5rem;
  line-height: normal;
  text-transform: none;
  color: var(--color-secondary-1);
  font-weight: 400;
  font-size: 0.95rem;
  background-color: #fff;
  overflow: hidden;
  // box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
  //   0 5px 15px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius-4);
}

.submitButton {
  background-color: var(--color-primary-2);
  color: #fff;
  border: 0;
  padding: 1rem 1.5rem;
  width: 100%;
  border-radius: var(--border-radius-4);
  cursor: pointer;
  font-weight: 500;
}

.link {
  color: var(--color-primary-2) !important; // to override the link coloring
  font-weight: 400;
}

.logoContainer {
  height: 8rem;
  margin-bottom: 1.5rem;
}

.logo {
  width: 6rem;
}

.signup {
  align-self: center;
  margin-top: 2rem;
}

.errorMsg {
  color: var(--color-fill-error);
  font-weight: 500;
  margin-bottom: 2rem;
  margin-top: -0.5rem;
  display: flex;
  align-items: center;
}

.errorIcon {
  color: var(--color-fill-error);
  font-weight: 500;
  // margin-right: 2px;
  margin-bottom: -2px;
}
