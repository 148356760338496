.br-4 {
  border-radius: 4px;
}

/* POSITIONING AND FLEX */
.d-f {
  display: flex;
}

.fd-r {
  flex-direction: row;
}

.fd-c {
  flex-direction: column;
}

.f-jc {
  justify-content: center;
}

.f-jsb {
  justify-content: space-between;
}

.f-jfe {
  justify-content: flex-end;
}

.f-aic {
  align-items: center;
}

.f-aibl {
  align-items: baseline;
}

.f-aife {
  align-items: flex-end;
}

.ta-c {
  text-align: center;
}

.op-15 {
  opacity: 0.15;
}

.wd-48 {
  width: 48%;
}

.wd-100 {
  width: 100%;
}

/* MARGIN AND PADDING */

.m-0a {
  margin: 0 auto;
}

.ml-5px {
  margin-left: 5px;
}

// Generate utilty padding and margin
// ex. margin-r-2, padding-b-2
@include repeat-unit-classes-map($repeatable-slugs, 0, 10, rem, 1);
