.logo {
  min-height: 3.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & img {
    display: block;
    margin: 0 auto;
    padding: 0.5rem;
    height: 3rem;
  }
}

.banner-ad {
  height: max-content !important;
  width: 100%;
}

.alreadyBookedSlot {
  font-weight: 500;
  color: red;
}

.ant-layout-sider-children {
  position: fixed;
  left: 0;
  top: 0;
}
.ant-layout-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 2px 7px -2px rgba(192, 192, 192, 0.62);
}
