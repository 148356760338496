.locale {
  &-container {
    margin-right: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  &-current {
    width: 1.5rem;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  &-flag {
    width: 1.5rem;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 4px;
  }
  &-flag-container {
    cursor: pointer;

    & img {
      margin-right: 0.5rem;
    }
    display: flex;
    align-items: center;
  }
}
