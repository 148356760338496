#nprogress .bar {
  z-index: 5000 !important;
  height: 3px !important;
  background: #3385ff !important;
}

#nprogress .peg {
  display: none !important;
}

/* antd overrides */
.ant-layout-header {
  display: flex;
  align-items: center;
}

.ant-menu-dark.ant-menu-vertical .ant-menu-item {
  margin-bottom: 1.5rem;
}

.ant-btn,
.ant-tag,
.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-tooltip,
.ant-tooltip-placement-left,
.ant-tooltip-content,
.ant-tooltip-inner,
.ant-modal-content,
.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: flex-end;
}

.ant-btn-primary {
  background: #180f77;
  border: 1px solid transparent;
  &:hover,
  &:focus {
    color: #fff;
    background: #180f77;
    opacity: 0.75;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

// .ant-radio-group-solid
//   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
//   color: #fff;
//   background: var(--color-primary-2);
//   border-color: transparent;
// }

// // .ant-radio-group-solid
// //   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
// //   color: #fff;
// //   background: var(--color-primary-2);
// //   border-color: transparent;
// // }

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--color-primary-2);
  border-color: transparent;
}
