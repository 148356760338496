.notificationMenu {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 20rem;

  & h2 {
    margin-bottom: 1.25rem;
  }
}

.notificationItem {
  display: flex;
  margin-bottom: 0.5rem;
}

modify .ant-popover {
  z-index: 0;
}

.Header {
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
