.form {
  padding: 4rem 5rem;
  border-radius: 5px;
  background-color: var(--color-bg-white);
  // box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
  //   rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  box-shadow: 0px 1px 5px #e0e0e0;
  display: flex;
  flex-direction: column;
  min-width: 32rem;

  &-subtitle {
    font-size: 1rem;
    opacity: 0.5;
  }

  &-msg--err {
    border-radius: 4px;
    background: var(--color-fill-error);
    color: var(--color-bg-white);
    padding: 0.5rem;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}
